import React from "react";
import { Button, Modal } from 'react-bootstrap';

export default function TermPopUp() {
  const [openTerm, setOpenTerm] = React.useState(false);

  const handleClose = () => setOpenTerm(false);
  const handleShow = () => setOpenTerm(true);


  return (
    <>
      <button
        id="myBtn"
        onClick={() => {
          setOpenTerm(true);
        }}>
        Privacy Policy and Conditions
      </button>

      {openTerm ? (
        <Modal show={openTerm} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Modal.Title>Privacy Policy and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      ) : null}
    </>
  );
}
