import { useState } from "react";
import Axios from "axios";

export default function RegisterTest({ registered, setRegister }) {
  const [regData, setRegData] = useState({
    email: "",
    password: "",
    full_name: "",
    agency: "",
    phone: "",
  });

  function handleSubmit(e) {
    e.preventDefault();

    let date = new Date("Fri Jan 20 2012 11:51:36 GMT +0800").toUTCString();

    if (regData.email && regData.full_name && regData.agency && regData.phone) {
      Axios.post("https://api.thelightcity.com.my/register", {
        email: regData.email,
        password: regData.password,
        full_name: regData.full_name,
        agency: regData.agency.replace(/[^a-z\d\s]+/gi, ""),
        phone: regData.phone,
        date: date,
      }).then((err, res) => {
        if (err) throw err;
        console.log(res);
      });
      setRegister(true);
    } else console.log("Complete form");
  }

  return (
    <div className="form-container">
      <h2>Registration</h2>
      {registered ? (
        <h5>
          Your details has been sent to the admin for verification, once
          approved you will get a custom virtual tour link and its password to
          host a live guided tour through email. Please contact your admin for
          any enquiries.
        </h5>
      ) : (
        <form onSubmit={(e) => handleSubmit(e)} method="POST">
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Email</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="email"
              value={regData.email}
              onChange={(e) =>
                setRegData({ ...regData, email: e.target.value })
              }
            />
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Full Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={regData.full_name}
              onChange={(e) =>
                setRegData({ ...regData, full_name: e.target.value })
              }
            />
          </div>
          {/* <div className="inputContainer">
                <label className="inputTitle"><p>Agency</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text" value={regData.agency} onChange={e => setRegData({...regData, agency: e.target.value})}/>
            </div> */}
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Agency</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <select
              placeholder="Agency"
              value={regData.agency}
              onChange={(e) =>
                setRegData({ ...regData, agency: e.target.value })
              }>
              <option style={{ display: "none" }}>Select Agency</option>
              <option>IJMPD</option>
              <option>GLP Properties</option>
              <option>Zeon Properties</option>
              <option>Hectarworld Properties</option>
              <option>IQI Realty</option>
              <option>Henry Butcher</option>
              <option>PE Realty</option>
              <option>UPP Realty</option>
              <option>Interealtor</option>
            </select>
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Phone Number</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              type="text"
              value={regData.phone}
              onChange={(e) =>
                setRegData({ ...regData, phone: e.target.value })
              }
            />
          </div>

          <button className="submit">Register</button>
        </form>
      )}
    </div>
  );
}
